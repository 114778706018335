// import 'tailwindcss/tailwind.css'
import { createApp } from 'vue';
import { ConfigProvider } from 'vant';
// toast/dialog编程式会丢失样式，需要单独引入
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/lib/index.css';
import '@/assets/styles/index.less';
import '@/plugins/amfeFlexible';
import il18 from '@/plugins/i18n';
import router from '@/router';
import pinia from '@/store';
import curRegion from '@/config/region';
import { setDayDefault } from '@/plugins/day';
import { formatTime, getAssetUrl, getFullUrl } from '@/utils/tools';
import App from './App.vue';

setDayDefault(curRegion);

const app = createApp(App);

// 全局属性
app.config.globalProperties.$curRegion = curRegion;
// app.config.globalProperties.$getAssetsFile = (url: string, folders: string = 'images') => new URL(`./assets/${folders}/${url}`, import.meta.url).href;
app.config.globalProperties.$getAssetsFile = getAssetUrl;
app.config.globalProperties.$getFullUrl = getFullUrl;
app.config.globalProperties.$formatTime = formatTime;

// 使用插件
app.use(il18).use(pinia).use(router).use(ConfigProvider);

// 全局错误处理
app.config.errorHandler = (err, _vm, info) => {
  console.error('未捕获的异常：', err, info);
};

app.mount('#app');
