import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import guards from './guards';

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    let currentPosition = {
      top: 0,
      left: 0
    };
    if (savedPosition) {
      currentPosition = savedPosition;
    }
    return currentPosition;
  }
});

// 注册导航守卫
guards.before.forEach(router.beforeEach);
guards.after.forEach(router.afterEach);

export default router;
