import { ref } from 'vue';
import { defineStore } from 'pinia';
import { ListResponse, RecordPlan } from '@/types/cards';
import { List, RecordList } from '@/apis/cards';

export const useCardsStore = defineStore('cards', () => {
  const cardList = ref<ListResponse['plans']>();
  const valldatedCardInfo = ref<RecordPlan>();

  async function setCardList() {
    const res = await List({});
    if (res?.code === 200) {
      cardList.value = res?.data?.plans;
      return res;
    } else {
      return Promise.reject(res);
    }
  }

  async function setCardvalldated() {
    const res = await RecordList({ status: 'STATUS_VALIDATED' });
    if (res?.code === 200) {
      valldatedCardInfo.value = res?.data?.plans?.[0];
      return res;
    } else {
      return Promise.reject(res);
    }
  }

  return {
    cardList,
    valldatedCardInfo,
    setCardList,
    setCardvalldated,
  };
});
