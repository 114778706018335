const ErrorMap = new Map([
  ['ECONNABORTED', '请求超时！'],
  ['500', '服务器出错！'],
  ['501', '服务器无法处理请求！'],
  ['502', '服务无响应！'],
  ['503', '服务不可用！'],
  ['504', '服务网关超时！'],
  ['11001', '路径不存在'],
  ['11002', '方法不允许'],
  ['11003', '服务器内部错误！'],
  ['11004', '登录已过期，请重新登录！'],
  ['11005', '等待OTP验证'],
  ['11006', '禁止访问'],
  ['11007', '请求频率超限'],
  ['11008', '非法访问！'],
  ['11009', '数据库错误！'],
  ['11010', '系统维护中！'],
  ['11011', '参数错误！'],
  ['14001', '密码强度不足'],
  ['14002', '邮箱不存在'],
  ['14003', '邮箱已被注册'],
  ['14004', '手机号不存在'],
  ['14005', '手机号已被注册'],
  ['14006', '用户名或密码错误'],
  ['14007', '密码错误'],
  ['14008', '用户已注册'],
  ['14009', '用户不存在'],
  ['14010', '设备不存在'],
  ['14011', '设备已存在'],
  ['14012', '邀请人不存在'],
  ['14013', '邀请人已存在'],
  ['14014', '用户已被禁用'],
  ['14015', '用户已被终止'],
  ['14016', 'OTP验证码错误'],
  ['14017', '认证不存在'],
  ['14018', '认证状态错误'],
  ['14019', '角色标识已存在'],
  ['14020', '角色不存在'],
  ['14021', '用户收藏不存在'],
  ['14022', '邀请人已被禁用'],
  ['15001', '交易对不存在'],
  ['15002', '币种不存在'],
  ['15003', '交易对已被禁用'],
  ['15004', '订单类型受限'],
  ['15005', '价格无效'],
  ['15006', '余额不足'],
  ['15007', '可用余额不足'],
  ['15008', '订单不存在'],
  ['15009', '成交记录不存在'],
  ['15010', '杠杆不匹配'],
  ['15011', '仓位已存在，无法设置杠杆'],
  ['15012', '存在委托订单，无法设置杠杆'],
  ['15013', '仓位不存在'],
  ['15014', '仓位不足'],
  ['15015', '交易已被禁用'],
] as const);

export type ErrorCode = (typeof ErrorMap extends Map<infer K, any> ? K : never);

export const getErrorMessage = (code: ErrorCode | string, msg?: string): string => {
  return ErrorMap.get(code as ErrorCode) || msg || '未知错误';
};

export default ErrorMap;
