import { createApi } from '.';
import { login, security } from './apiMapping';
import {
  RegisterParams,
  LoginParams,
  LoginResponse,
  CodesSendParams,
  MessagesSendParams,
} from '@/types/login';

export const Register = createApi<RegisterParams, LoginResponse>(login.register.url);
export const Login = createApi<LoginParams, LoginResponse>(login.login.url);
export const CodesSend = createApi<CodesSendParams, unknown>(security.codesSend.url);
export const MessagesSend = createApi<MessagesSendParams, unknown>(security.messagesSend.url);
