import { AxiosResponse } from 'axios';
import NProgress from 'nprogress';
import { showNotify } from 'vant';
import createHttp from '@/utils/axiosHttp';
import { useLogin } from '@/store/modules/login';
import { getErrorMessage } from './errorCodeMsg';

const https = createHttp({
  timeout: 10000,
  baseURL: __APP_BASE_URL__,
  xsrfCookieName: 'Token',
});

const isResponse = (obj: any): obj is { message: string; code: number } =>
  obj && typeof obj === 'object' && 'message' in obj && 'code' in obj;

const isAxiosResponse = (obj: any): obj is AxiosResponse =>
  obj && typeof obj === 'object' && 'status' in obj && 'statusText' in obj && 'headers' in obj && 'config' in obj;

https.interceptors.request.use((req) => {
  const { Token } = useLogin();
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  if (Token) {
    req.headers['Authorization'] = Token;
  }
  return req;
});

https.interceptors.response.use(
  (rep: AxiosResponse<any>) => {
    NProgress.done();
    const { data } = rep;

    if (!isResponse(data)) {
      showNotify({ type: 'danger', message: rep.statusText });
      return Promise.reject({ message: rep.statusText, code: rep.status, data });
    }

    const code = Number(data.code);
    const msg = getErrorMessage(`${code}`, data.message);

    if (code === 200) {
      return rep.data;
    }

    showNotify({ type: 'danger', message: msg });
    if (code === 11004) {
      useLogin().resetAll();
    }
    return rep.data;
    // return Promise.reject(data);
  },
  (error) => {
    NProgress.done();

    const errorMessage = getErrorMessage(`${error?.response?.status || error.code}`, error.message);
    showNotify({ type: 'danger', message: errorMessage });

    if (error.response && isAxiosResponse(error.response)) {
      return Promise.reject({
        message: error.response.statusText,
        code: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject(error);
  }
);

export default https;
