import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { defineStore } from 'pinia';
import { showSuccessToast } from 'vant';
import { RegisterParams, LoginParams } from '@/types/login';
import { Register, Login } from '@/apis/login';
import { useAccountStore } from './account';
// import router from '@/router';

export const useLogin = defineStore(
  'login',
  () => {
    const Token = ref<string>('');
    const token = computed(() => Token.value);

    const route = useRoute();
    const router = useRouter();
    const { getAccount } = useAccountStore();

    async function goPath(type: 'register' | 'login') {
      const res = await getAccount();
      if (res?.code !== 200) {
        return;
      }
      showSuccessToast((type === 'register' ? '注册' : '登录') + '成功');
      const timer = setTimeout(() => {
        clearTimeout(timer);
        router.replace((route.query?.redirect || '/home') as string);
      }, 1500);
    }

    async function register(params: RegisterParams) {
      const res = await Register(params);
      if (res?.code === 200) {
        const { data } = res;
        Token.value = 'Bearer ' + data.token;
        goPath('register');
        return res;
      } else {
        return Promise.reject(res);
      }
    }

    async function login(params: LoginParams) {
      const res = await Login(params);
      if (res?.code === 200) {
        const { data } = res;
        Token.value = 'Bearer ' + data.token;
        goPath('login');
        return res;
      } else {
        return Promise.reject(res);
      }
    }

    async function logout() {
      return new Promise<boolean>((resolve) => {
        clearStore();
        resolve(true);
      });
    }

    function resetAll(isClear?: boolean) {
      clearStore();
      const currentState = (router?.options?.history?.state?.current ?? '').toString();
      const currentParts = currentState.split('/login');
      const redirect = isClear ? '' : currentParts[currentParts.length - 1] || '';
      const path = redirect
        ? `/login${redirect.includes('redirect') ? redirect : `?redirect=${encodeURIComponent(redirect)}`}`
        : '/login';
      router.replace(path);
    }

    function clearStore() {
      localStorage.removeItem('Account');
      localStorage.removeItem('Login');
    }

    return {
      Token,
      token,
      register,
      login,
      logout,
      resetAll,
    };
  },
  {
    persist: {
      key: 'Login',
      pick: ['Token'],
    },
  }
);
