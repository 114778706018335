import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const GlobalImg = ref<string>();

    async function setGlobalImg(path: string) {
      GlobalImg.value = path;
    }

    return {
      GlobalImg,
      setGlobalImg,
    };
  },
  {
    persist: {
      key: 'Settings',
      pick: ['GlobalImg'],
    },
  }
);
