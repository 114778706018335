import { ref } from 'vue';
import { defineStore } from 'pinia';
import { PlanListResponse } from '@/types/mission';
import { PlanList } from '@/apis/mission';

export const useMissionStore = defineStore('mission', () => {
  const planList = ref<PlanListResponse['plans']>();

  async function setPlanList() {
    const res = await PlanList({});
    if (res?.code === 200) {
      planList.value = res?.data?.plans;
      return res;
    } else {
      return Promise.reject(res);
    }
  }

  return {
    planList,
    setPlanList,
  };
});
