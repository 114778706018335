import { createI18n } from 'vue-i18n';
import { getLanguageGroup, currentLocale as locale } from '@/config/languages';

const languageGroup = getLanguageGroup();
const _lKeys = Object.keys(Object.values(languageGroup)[0]);

export const localeKeys = _lKeys;
// 创建i18n
const i18n = createI18n({
	locale,
	globalInjection: true,
	legacy: false,
	messages: {
		...languageGroup
	}
});

// 语言配置
export default i18n;
