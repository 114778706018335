import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      hasHeader: true,
      bgClass: 'login',
    },
    component: () => import('@/pages/Login'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      hasHeader: true,
      bgClass: 'login',
    },
    component: () => import('@/pages/Register'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页',
      i18nKey: 'pages.home',
      hasFooter: true,
    },
    component: () => import('@/pages/Home'),
  },
  {
    path: '/recommend',
    name: 'recommend',
    meta: {
      title: '推荐',
      i18nKey: 'pages.recommend',
      bgClass: 'base',
      hasFooter: true,
    },
    component: () => import('@/pages/Recommend'),
  },
  {
    path: '/mission',
    name: 'mission',
    meta: {
      title: '任务',
      i18nKey: 'pages.mission',
      bgClass: 'mission',
    },
    children: [
      {
        path: '',
        name: 'missionHall',
        meta: {
          title: '任务大厅',
          i18nKey: 'pages.mission',
          hasFooter: true,
        },
        component: () => import('@/pages/Mission'),
      },
      {
        path: 'group',
        name: 'missionGroup',
        meta: {
          title: '任务包',
          i18nKey: 'pages.group',
          hasHeader: true,
        },
        component: () => import('@/pages/Mission/group.vue'),
      },
      {
        path: 'promotion',
        name: 'missionPromotion',
        meta: {
          title: '推广中心',
          i18nKey: 'pages.promotion',
          hasHeader: true,
        },
        component: () => import('@/pages/Mission/promotion.vue'),
      },
    ],
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '我的',
      i18nKey: 'pages.user',
      hasFooter: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/User'),
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '搜索',
      i18nKey: 'pages.search',
      hasHeader: true,
    },
    component: () => import('@/pages/Search'),
  },
  {
    path: '/videos',
    name: 'videos',
    meta: {
      hasHeader: true,
    },
    children: [
      {
        path: 'detail/:id',
        name: 'videosDetail',
        meta: {
          hasHeader: { logo: true },
        },
        component: () => import('@/pages/Videos/detail.vue'),
      },
      {
        path: 'play/:id/:episodeId',
        name: 'videosPlay',
        meta: {
          hasHeader: { bgTransparent: true },
        },
        component: () => import('@/pages/Videos/play.vue'),
      },
    ],
  },
  {
    path: '/securities',
    name: 'securities',
    meta: {
      title: '帐号安全',
      hasHeader: true,
      bgClass: 'base',
    },
    children: [
      {
        path: '/securities',
        name: 'securityList',
        meta: {
          title: '帐号安全',
          i18nKey: 'pages.language',
        },
        component: () => import('@/pages/Securities'),
      },
      {
        path: 'certifications',
        name: 'certifications',
        meta: {
          title: '认证',
          i18nKey: 'pages.certifications',
          hasHeader: true,
          bgClass: 'base',
        },
        component: () => import('@/pages/Securities/certifications.vue'),
      },
      {
        path: 'forgot',
        name: 'forgot',
        meta: {
          title: '忘记密码',
          i18nKey: 'pages.forgot',
        },
        component: () => import('@/pages/Securities/forgot.vue'),
      },
      {
        path: 'verify',
        name: 'verify',
        meta: {
          title: '安全认证',
          i18nKey: 'pages.verify',
        },
        component: () => import('@/pages/Securities/verify.vue'),
      },
      {
        path: 'modifyAccount',
        name: 'modifyAccount',
        meta: {
          title: '更改',
          i18nKey: 'pages.modify',
        },
        component: () => import('@/pages/Securities/modifyAccount.vue'),
      },
      {
        path: 'modifyPayPassword',
        name: 'modifyPayPassword',
        meta: {
          title: '更改支付密码',
          i18nKey: 'pages.modifyPayPassword',
        },
        component: () => import('@/pages/Securities/modifyPayPassword.vue'),
      },
      {
        path: 'modifyPassword',
        name: 'modifyPassword',
        meta: {
          title: '重设密码',
          i18nKey: 'pages.modifyPassword',
        },
        component: () => import('@/pages/Securities/modifyPassword.vue'),
      },
    ],
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: '设置',
      hasHeader: true,
    },
    children: [
      {
        path: 'language',
        name: 'language',
        meta: {
          title: '语言设置',
          i18nKey: 'pages.language',
        },
        component: () => import('@/pages/Settings/language.vue'),
      },
    ],
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      title: '历史记录',
      i18nKey: 'pages.history',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/History'),
  },
  {
    path: '/favorites',
    name: 'favorites',
    meta: {
      title: '我的收藏',
      i18nKey: 'pages.favorites',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/Favorites'),
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      title: '我的订单',
      hasHeader: true,
      bgClass: 'base',
    },
    children: [
      {
        path: '',
        name: 'myOrders',
        meta: {
          title: '我的订单',
          i18nKey: 'pages.myOrders',
        },
        component: () => import('@/pages/Orders'),
      },
      {
        path: 'generate/:type/:id',
        name: 'orderGenerate',
        meta: {
          title: '订单',
          i18nKey: 'pages.orderGenerate',
        },
        component: () => import('@/pages/Orders/generate.vue'),
      },
      {
        path: 'pay/:id',
        name: 'orderPay',
        meta: {
          title: '确认支付',
          i18nKey: 'pages.orderPay',
        },
        component: () => import('@/pages/Orders/pay.vue'),
      },
      {
        path: 'info/:type/:id',
        name: 'orderInfo',
        meta: {
          title: '订单详情',
          i18nKey: 'pages.orderDetail',
        },
        component: () => import('@/pages/Orders/info.vue'),
      },
    ],
  },
  {
    path: '/integral',
    name: 'integral',
    meta: {
      title: '我的积分',
      hasHeader: true,
    },
    children: [
      {
        path: '',
        name: 'myIntegral',
        meta: {
          title: '我的积分',
          i18nKey: 'pages.integral',
        },
        component: () => import('@/pages/Integral'),
      },
      {
        path: 'transfer',
        name: 'integralTransfer',
        meta: {
          title: '积分转让',
          i18nKey: 'pages.integralTransfer',
          hasHeader: true,
          bgClass: 'base',
        },
        component: () => import('@/pages/Integral/transfer.vue'),
      },
    ],
  },
  {
    path: '/cards',
    name: 'myCards',
    meta: {
      title: '我的卡券',
      i18nKey: 'pages.cards',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/Cards'),
  },
  {
    path: '/protocols',
    name: 'protocols',
    meta: {
      title: '协议',
      hasHeader: true,
    },
    children: [
      {
        path: 'distributor',
        name: 'distributor',
        meta: {
          title: '分销商',
          i18nKey: 'pages.distributor',
        },
        component: () => import('@/pages/Protocols/distributor.vue'),
      },
      {
        path: 'service',
        name: 'service',
        meta: {
          title: '服务协议',
          i18nKey: 'pages.service',
        },
        component: () => import('@/pages/Protocols/service.vue'),
      },
      {
        path: 'privacy',
        name: 'privacy',
        meta: {
          title: '隐私协议',
          i18nKey: 'pages.privacy',
        },
        component: () => import('@/pages/Protocols/privacy.vue'),
      },
    ],
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      title: '关于我们',
      i18nKey: 'pages.aboutUs',
      hasHeader: true,
    },
    component: () => import('@/pages/AboutUs'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: {
      name: 'home',
    },
  },
];

export default routes;
