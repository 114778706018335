import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { showSuccessToast } from 'vant';
import { GroupListResponse, CollectStatus } from '@/types/playlet';
import { GroupList, CollectUpdate } from '@/apis/playlet';

export const usePlayletStore = defineStore(
  'playlet',
  () => {
    const PlayGroupList = ref<GroupListResponse['groups']>();
    const playGroupList = computed(() => PlayGroupList.value);

    async function setPlayGroupList() {
      const res = await GroupList({});
      if (res?.code === 200) {
        PlayGroupList.value = res?.data?.groups;
        return res;
      } else {
        return Promise.reject(res);
      }
    }

    async function setCollectUpdate(ids: string[], status: CollectStatus = 'COLLECT_STATUS_COLLECTING') {
      const res = await CollectUpdate({ ids, status });
      if (res?.code !== 200) return;
      showSuccessToast(status === 'COLLECT_STATUS_COLLECTING' ? '收藏成功' : '取消收藏成功');
      return true;
    }

    return {
      playGroupList,
      PlayGroupList,
      setPlayGroupList,
      setCollectUpdate,
    };
  },
  {
    persist: {
      key: 'Playlet',
      pick: ['PlayGroupList'],
    },
  }
);
