<script setup lang="ts">
  import { ref, watch, defineAsyncComponent, onBeforeMount } from 'vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import VConsole from '@/plugins/vconsole';
  import { languageKeys, currentLocale } from '@/config/languages';
  import { setLocale } from '@/plugins/vant';
  import { usePlayletStore } from '@/store/modules/playlet';

  const AppHeader = defineAsyncComponent(() => import('@/components/AppHeader.vue'));
  const AppFooter = defineAsyncComponent(() => import('@/components/AppFooter'));

  // const store = useStore();
  const { locale, t: $i18nt } = useI18n();
  const route = useRoute();
  const { meta } = route;
  const addHeader = ref<boolean>(meta.addHeader as boolean);
  const addFooter = ref<boolean>(meta.addFooter as boolean);
  const bgInfo: any = {
    base: 'bg-[url("@/assets/images/common/bg_app_01.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_-106px]',
    login: 'bg-[url("@/assets/images/common/bg_app_02.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_0]',
    mission: 'bg-[url("@/assets/images/common/bg_app_03.png")] bg-no-repeat bg-[length:100%_auto] bg-[0_0]',
  };

  watch(route, (toRoute) => {
    const {
      meta: { hasHeader, hasFooter },
    } = toRoute;
    addHeader.value = !!hasHeader;
    addFooter.value = !!hasFooter;
    setVConsole();
  });

  // vconsole手机调试工具
  const vConsole = ref<any>(null);
  const setVConsole = () => {
    if (route.query.debug && !vConsole.value) {
      vConsole.value = VConsole();
    }
  };

  // 非配置表的语言时，默认使用配置表第一项语言
  const changeLocation = (language: string) => {
    locale.value = language;
    setLocale(language);
    document.title = $i18nt('menus.languageSetting');
    // store.commit('setLocale', language);
  };
  if (currentLocale && !languageKeys.includes(currentLocale)) {
    changeLocation(languageKeys[0]);
  }

  const { playGroupList, setPlayGroupList } = usePlayletStore();
  onBeforeMount(() => {
    !playGroupList && setPlayGroupList();
  });
</script>

<template>
  <suspense>
    <van-config-provider
      theme="dark"
      class="overflow-hidden text-[12px] text-white"
      :class="bgInfo[route?.meta?.bgClass as string]">
      <AppHeader v-if="addHeader" />
      <router-view v-slot="{ Component }" :class="[addFooter ? 'pb-[68px]' : '', addHeader ? 'pt-[46px]' : '']">
        <keep-alive>
          <component :is="Component" v-if="route.meta.keepAlive" :key="route.name" />
        </keep-alive>
        <component :is="Component" v-if="!route.meta.keepAlive" :key="route.name" />
      </router-view>
      <AppFooter v-if="addFooter" />
    </van-config-provider>
  </suspense>
  <!-- 开启底部安全区适配 -->
  <!-- <van-number-keyboard safe-area-inset-bottom /> -->
</template>

<style scoped lang="less"></style>
