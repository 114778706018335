import { ref } from 'vue';
import { defineStore } from 'pinia';
import { GetResponse } from '@/types/integral';
import { Get } from '@/apis/integral';

export const useIntegralStore = defineStore('integral', () => {
  const integralInfo = ref<GetResponse>();

  async function setIntegral() {
    const res = await Get({});
    if (res?.code === 200) {
      const { data } = res;
      integralInfo.value = data;
      return res;
    } else {
      return Promise.reject(res);
    }
  }

  return {
    integralInfo,
    setIntegral,
  };
});
