import Dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

Dayjs.extend(utc);
Dayjs.extend(timezone);

export const setDayDefault = (currentMarket: { timezone: string; name: string }) => {
  Dayjs.tz.setDefault(currentMarket.timezone);
  console.log(Dayjs().format('YYYY-MM-DD HH:mm:ss'), '- 中国时区');
  console.log(Dayjs.tz().format('YYYY-MM-DD HH:mm:ss'), `- ${currentMarket.name}时区`);
  // dayjs() // 依旧是服务器/本地时区
  // dayjs.tz() // 这样才是指定的时区
};
