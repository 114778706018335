import { Locale } from 'vant';
import { languageList, currentLocale } from '@/config/languages';
import vantLocale from './locale';

export const setLocale = async (lang: string) => {
  const current = await (vantLocale[lang] ? vantLocale[lang] : vantLocale.en)();
  Locale.use(languageList[lang]?.value || 'en-US', current.default);
};

setLocale(currentLocale);
