import langData from './data.json';

// 语言配置表
export const languageList = {
    // 巴基斯坦（乌尔都语）
    urdu: {
        name: 'پاکستان（اردو）',
        value: 'Urdu'
    },
    // 英语
    en: {
        name: 'English',
        value: 'en'
    },
    // 简体中文
    zh: {
        name: '简体中文',
        value: 'zh'
    },
    // // 葡萄牙语（巴西）
    // pt: {
    //     name: 'Português',
    //     value: 'pt-BR'
    // },
    // // 印地语
    // hi: {
    //     name: 'हिंदी',
    //     value: 'hi-IN'
    // },
};

// 语言key列表
export const languageKeys = Object.keys(languageList);
// 当前语言环境
export const currentLocale = window?.localStorage?.getItem('language') || languageKeys[1];
// 当前语言集合
export const getLanguageGroup = () => {
    const _lang = {};
    languageKeys.map((lanKey: string) => {
        _lang[lanKey] = langData[lanKey];
    });
    return _lang;
};
